import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalDb {

  public imgSrc = 'imgSrc';
  public logo = 'logo';
  public menu = 'menu';
  public myPrinter = 'myPrinter';
  public myResto = 'myResto';
  public token = 'token';
  public user = 'user';
  public username = 'username';
  public restaurant = 'restaurant';

  private dbId = 'gaarcon_';

  constructor() { }

  set(code, valeur) {
    localStorage.setItem(this.dbId + code, JSON.stringify(valeur, null, 2));
  }

  get(code) {
    return this.jsonParse(localStorage.getItem(this.dbId + code));
  }

  remove(code) {
    localStorage.removeItem(this.dbId + code);
  }

  jsonParse(o) {
    if (o !== 'undefined') {
      return JSON.parse(o);
    }
    return null;
  }

  signOut() {
    this.remove(this.token);
    this.remove(this.user);
    this.remove(this.restaurant);
    this.remove(this.logo);
    this.remove(this.menu);
  }
}
