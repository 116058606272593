import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        authorization: `Bearer ${this.authService.getToken()}`
      }
    });
    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) { }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.authService.signOut();
        }
      }
    ));
  }

}
