import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full'
  },
  {
    path: 'orders',
    loadChildren: () => import('src/app/pages/orders/orders.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./components/order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'dish',
    loadChildren: () => import('./components/dish/dish.module').then( m => m.DishPageModule)
  },
  {
    path: 'pos-printer',
    loadChildren: () => import('./components/pos-printer/pos-printer.module').then( m => m.PosPrinterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'restaurants',
    loadChildren: () => import('./pages/restaurants/restaurants.module').then( m => m.RestaurantsPageModule)
  },
  {
    path: 'restaurant',
    loadChildren: () => import('./pages/restaurant/restaurant.module').then( m => m.RestaurantPageModule)
  },
  {
    path: 'menu-list',
    loadChildren: () => import('./pages/menu-list/menu-list.module').then( m => m.MenuListPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
