import { Injectable } from '@angular/core';
import { LocalDb } from '../core/localdb';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private localDb: LocalDb,
    public request: RequestService
    ) { }

  signIn(username, password) {
    return this.request.post('api/login_connect', {
      username,
      password
    });
  }

  session() {
    return this.request.get('api/session');
  }

  getToken() {
    return this.localDb.get(this.localDb.token);
  }

  isAuthenticated() {
    return this.getToken();
  }

  signOut() {

  }
}
