import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MyUtils {

  constructor() { }

  getRGB(hex) {
    return parseInt(hex.substring(1, 3), 16) + ',' + parseInt(hex.substring(3, 5), 16) + ',' + parseInt(hex.substring(5, 7), 16);
  }
}
