import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import { LocalDb } from './core/localdb';
import { MyApp } from './core/my-app';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public isLarge = false;

  constructor(
    private authService: AuthService,
    private metaTags: Meta,
    private myApp: MyApp,
    public localDb: LocalDb,
    public platform: Platform,
    public router: Router,
  ) { }

  ngOnInit() {
    this.metaTags.addTags([
      {
        name: 'description',
        content: 'The best japanese sushi restaurant in Lyon. Le meilleur restaurant de sushis japonais de Lyon.'
      },
      {
        name: 'author',
        content: 'AKENSYS'
      },
      {
        name: 'keywords',
        content: 'chinese, japanese, vietnamese, food, sushi, restaurant, chinese restaurant,'
          + ' japanese restaurant, vietnamese restaurant, sushi restaurant'
      }
    ]);

    this.authenticateUser();
  }

  authenticateUser() {
    const user = this.localDb.get(this.localDb.user);
    if (this.authService.isAuthenticated() && user) {
      this.myApp.initialize(user);
      this.router.navigateByUrl('orders');
    } else {
      this.router.navigateByUrl('login');
    }
  }
}
