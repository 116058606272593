/* eslint-disable @typescript-eslint/naming-convention */
import * as Color from 'color';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { MenuService } from '../services/menu.service';
import { LocalDb } from './localdb';
import { MyUtils } from './my-utils';

@Injectable({
  providedIn: 'root'
})
export class MyApp {

  public user: any;

  public readonly ORDER_TYPE = {
    ON_SITE: 'S',
    TO_TAKE_AWAY: 'E',
    DELIVERY: 'L'
  };

  public readonly PARTS = {
    PART1 : 'PART_1',
    PART2 : 'PART_2',
    PART3 : 'PART_3',
  };

  public menu;

  constructor(
    private localDb: LocalDb,
    private myUtils: MyUtils,
    private platform: Platform,
    @Inject(DOCUMENT) public document: Document
  ) { }

  initialize(user) {
    this.assignUser(user);
    this.subscribeBackButton();
    this.setLang();
    this.enableMenu();
    this.setTheme();
  }

  assignUser(user) {
    this.user = user;
    this.localDb.set(this.localDb.user, user);
    this.localDb.set(this.localDb.username, user.login);
  }

  subscribeBackButton() {
    this.platform.backButton.subscribeWithPriority(1, () => { });
  }

  setLang() { }

  enableMenu() { }

  setTheme() {
    const primary = this.user.client?.couleur_primary ?? '#f96858';
    const secondary = this.user.client?.couleur_secondary ?? '#383e42';
    const secondary2 = this.user.client?.couleur_secondary2 ?? '#383e42';
    const shadeRatio = 0.1;
    const css = `
    --ion-color-primary: ${primary};
    --ion-color-primary-rgb: ${this.myUtils.getRGB(primary)};
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: ${Color(primary).darken(shadeRatio)};

    --ion-color-secondary: ${secondary};
    --ion-color-secondary-rgb: ${this.myUtils.getRGB(secondary)};
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: ${Color(secondary).darken(shadeRatio)};

    --ion-color-secondary2: ${secondary2};
    --ion-color-secondary2-rgb: ${this.myUtils.getRGB(secondary2)};
    --ion-color-secondary2-contrast: #ffffff;
    --ion-color-secondary2-contrast-rgb: 255,255,255;
    --ion-color-secondary2-shade: ${Color(secondary2).darken(shadeRatio)};
    // omitted other styles, see full source code
    `;
    this.document.documentElement.style.cssText = css;
  }
}
